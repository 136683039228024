<!-- =========================================================================================
  File Name: AppEdit.vue
  Description: User Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="page-app-edit">
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="app_not_found"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        App with hash: {{ $route.params.appHash }} not found. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps' }"
        >
          all apps.
        </b-link>
      </div>
    </b-alert>
    <AppEditIntegrationWizard
      :app-data="app_data"
      :on-active="activeTab === 0"
    />
    <b-card no-body>
      <b-tabs
        v-if="app_data !== null"
        v-model="activeTab"
        pills
        style="position: relative;"
        class="custom-tabs"
      >
        <!-- Tab: Account -->
        <b-tab active>
          <template #title>
            <feather-icon
              icon="CpuIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Integration</span>
          </template>
          <app-edit-integration
            ref="AppEditIntegration"
            v-model="app_data"
          />
        </b-tab>
        <!-- Tab: Information -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="InfoIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">General</span>
          </template>
          <app-edit-general
            ref="AppEditGeneral"
            v-model="app_data"
            @reset="resetData($event)"
            @request-live="updateStatus($event)"
          />
        </b-tab>
        <!-- Tab: Social -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="AwardIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Rewards</span>
            <b-badge
              v-if="!haveSetupOnTabReward"
              v-b-tooltip.hover.bottom
              variant="danger"
              class="tab-title-badge"
              title="Please complete Rewards Settings"
            >&nbsp;
            </b-badge>
          </template>
          <app-edit-rewards
            ref="AppEditRewards"
            v-model="app_data"
            :i-frame-url-query="iFrameURLQuery"
            @reset="resetData($event)"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="CornerDownLeftIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Redirects</span>
          </template>
          <app-edit-redirects
            ref="AppEditRedirects"
            v-model="app_data"
            @reset="resetData($event)"
          />
        </b-tab>
        <!-- Tab: Social -->
        <b-tab>
          <template #title>
            <feather-icon
              icon="CornerDownLeftIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Postback</span>
            <b-badge
              v-if="!haveSetupOnTabPostback"
              v-b-tooltip.hover.bottom
              variant="danger"
              class="tab-title-badge"
              title="Please complete Postback Settings"
            >&nbsp;
            </b-badge>
          </template>
          <app-edit-postback
            ref="AppEditPostBack"
            v-model="app_data"
            @reset="resetData($event)"
          />
        </b-tab>
        <!-- Tab: Social -->
        <b-tab>
          <template
            #title
            class="tab-title"
          >
            <feather-icon
              icon="ImageIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">Design</span>
          </template>
          <app-edit-design
            ref="AppEditDesign"
            v-model="app_data"
            @reset="resetData($event)"
            @iFrameURLQueryChanged="iFrameURLQuery = $event"
          />
        </b-tab>

        <template #tabs-end>
          <li style="position: absolute; right: 1.5rem;">
            <span
              v-if="haveUnsavedChanges"
              style="margin-right: 1rem; font-size: small; color: var(--warning);"
            >You have unsaved changes</span>
            <b-button
              variant="primary"
              @click="saveAllChanges()"
            >Save changes
            </b-button>
            <!--                <b-button
                                variant="outline-secondary"
                                @click="resetAllChanges()"
                              >Reset all</b-button>-->
          </li>
        </template>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import AppEditIntegration from '@ps_views/apps/edit/AppEditIntegration.vue'
import AppEditIntegrationWizard from '@ps_views/apps/edit/AppEditIntegrationWizard.vue'
import AppEditGeneral from '@ps_views/apps/edit/AppEditGeneral.vue'
import AppEditRewards from '@ps_views/apps/edit/AppEditRewards.vue'
import AppEditPostback from '@ps_views/apps/edit/AppEditPostback.vue'
import AppEditDesign from '@ps_views/apps/edit/AppEditDesign.vue'
import AppEditRedirects from '@ps_views/apps/edit/AppEditRedirects.vue'

export default {
  components: {
    AppEditIntegration,
    AppEditIntegrationWizard,
    AppEditGeneral,
    AppEditRewards,
    AppEditPostback,
    AppEditDesign,
    AppEditRedirects,
    // CustomVsTab
  },
  data() {
    return {
      app_data: null,
      app_org_data: null,
      app_not_found: false,
      activeTab: 0,
      iFrameURLQuery: '',
    }
  },
  computed: {
    haveUnsavedChanges() {
      return JSON.stringify(this.app_org_data) !== JSON.stringify(this.app_data)
    },
    haveSetupOnTabReward() {
      let isValidReward = false
      if (this.app_data.reward_type !== 0) isValidReward = true
      else if (this.app_data.fixed_reward) isValidReward = true
      if (this.app_data.currency_exchange_rate && this.app_data.currency_name && isValidReward) return true
      return false
    },
    haveSetupOnTabPostback() {
      if (this.app_data.postback_url && this.isValidHttpUrl(this.app_data.postback_url)) return true
      return false
    },
  },
  watch: {
    activeTab() {
      // this.fetch_user_data(this.$route.params.userId)
    },
  },
  created() {
    const { appHash } = this.$route.params
    this.$http.get(`/api/apps/get/${appHash}`).then(response => {
      this.app_data = response.data.app
      this.app_org_data = JSON.parse(
        JSON.stringify(response.data.app),
      )
      this.hideLoading()
    }).catch(() => {
      this.app_not_found = true
    })
  },
  mounted() {
    window.AppEdit = this
  },
  methods: {
    isValidHttpUrl(string) {
      let url

      try {
        url = new URL(string)
      } catch (_) {
        return false
      }

      return url.protocol === 'http:' || url.protocol === 'https:'
    },
    saveAllChanges() {
      this.showLoading();
      (async () => {
        const results = await Promise.all(this.validateAll())
        let hasError = false
        results.forEach((validatedResult, index) => {
          if (validatedResult === false) {
            this.activeTab = index
            hasError = true
          }
        })
        if (hasError) {
          this.hideLoading()
        } else {
          this.sendRequestToApi()
        }
      })()
    },
    sendRequestToApi() {
      this.$http.post(
        `/api/apps/update/${this.app_data.hash}`,
        this.getFormData(),
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      ).then(response => {
        this.hideLoading()
        this.alertSuccess(response.data.message)
        const appData = response.data.app
        this.app_data = appData
        this.app_org_data = JSON.parse(
          JSON.stringify(appData),
        )
      }).catch(errors => {
        this.hideLoading()
        if (errors && errors.response && errors.response.data) {
          this.alertError(errors.response.data.errors)
        }
      })
    },
    getFormData() {
      this.showLoading()
      const formData = new FormData()

      Object.entries(this.app_data).forEach(([key, value]) => {
        formData.append(key, value === null ? '' : value)
      })
      return formData
    },
    validateAll() {
      const tabNames = ['AppEditIntegration', 'AppEditGeneral', 'AppEditRewards', 'AppEditPostBack', 'AppEditDesign']

      return tabNames.map(tabName => (
        this.$refs[tabName] && this.$refs[tabName].$refs && this.$refs[tabName].$refs.main_form
          ? this.$refs[tabName].$refs.main_form.validate()
          : Promise.resolve(true)
      ))
    },
    resetAllChanges() {
      this.app_data = JSON.parse(JSON.stringify(this.app_org_data))
    },
    resetData(listProperties) {
      listProperties.forEach(key => {
        this.app_data[key] = this.app_org_data[key]
      })
    },
    updateStatus(newStatus) {
      this.app_data.status = newStatus
      this.app_org_data.status = newStatus
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style>
#page-app-edit .custom-tabs > div {
  padding: 1.5rem;
  border-bottom: 1px solid #d8d8d8;
}

#page-app-edit .custom-tabs > div > ul {
  margin-bottom: 0px !important;
}

#page-app-edit .custom-tabs .tab-content {
  padding: 1.5rem;
}

.tab-title-badge {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  width: 14px;
  height: 14px;
  overflow: hidden;
  border-radius: 1rem;
  opacity: 0.5;
}

.tabs.custom-tabs .nav .nav-item {
  position: relative;
  border: 1px solid #ddd;
  margin: 0 1rem;
  border-radius: 0.5rem;
}
</style>
