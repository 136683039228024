<template>
  <div id="user-edit-tab-info">
    <div v-if="mounted">
      <validation-observer
        ref="main_form"
        v-slot="{ validated }"
      >
        <b-row>
          <b-col cols="12">
            <!-- GENERAL -->
            <div class="offer-info-block">
              <div style="padding: 1.143rem 1.714rem">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar
                      size="40"
                      variant="light-primary"
                    >
                      <feather-icon
                        size="20"
                        icon="BookIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <div class="offer-info-block-title">
                      General
                    </div>
                  </b-media-body>
                </b-media>
              </div>
              <hr style="margin: 0;">
              <div style="padding: 1.714rem;">
                <b-row>
                  <b-col cols="9">
                    <b-row style="padding-bottom: 0.857rem;">
                      <b-col cols="12">
                        <label>Currency name</label>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        cols="6"
                        style="padding-right: 2.286rem; border-right: 0.143rem solid #ddd;"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="currency name"
                          :rules="{ required: true }"
                        >
                          <b-input
                            v-model="currency_name"
                            name="currency_name"
                          />
                          <small
                            v-if="validated && errors.length > 0"
                            class="text-danger"
                          >{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                      <b-col
                        cols="6"
                        style="padding-left: 2.286rem;"
                      >
                        <div class="currency-description">
                          Name of your in-app currency in plural
                          form<br>Example: Diamonds, Coins, Credits
                        </div>
                      </b-col>
                    </b-row>
                    <b-row style="margin-top: 2.857rem; padding-bottom: 0.857rem;">
                      <b-col cols="12">
                        <label>Currency exchange rate</label>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        cols="6"
                        style="padding-right: 2.286rem; border-right: 0.143rem solid #ddd;"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="currency exchange rate"
                          :rules="{ required: true, min_value: 10 }"
                        >
                          <b-input
                            v-model="currency_exchange_rate"
                            type="number"
                            name="currency_exchange_rate"
                          />
                          <small
                            v-if="validated && errors.length > 0"
                            class="text-danger"
                          >{{ errors[0] }}</small>
                          <small
                            v-if="currency_exchange_rate && currency_name"
                            class="text-secondary"
                          >Users will rewarded {{ currency_exchange_rate }}
                            {{ currency_name }} for every 1$ you earn</small>
                        </validation-provider>
                      </b-col>
                      <b-col
                        cols="6"
                        style="padding-left: 2.286rem;"
                      >
                        <div class="currency-description">
                          Equivalent of 1 $ in your in-app
                          currency.<br>Example: 100 (Will reward the
                          user 100 "points" for each 1 $ you earn.)<br>
                        </div>
                      </b-col>
                    </b-row>

                    <b-row style="margin-top: 2.857rem; padding-bottom: 0.857rem;">
                      <b-col cols="12">
                        <label>Currency exchange rate (Screenout Rewards)</label>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        cols="6"
                        style="padding-right: 2.286rem; border-right: 0.143rem solid #ddd;"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Currency exchange rate (Screenout Rewards)"
                          :rules="{ required: true, min_value: 10 }"
                        >
                          <b-input
                            v-model="currency_exchange_rate_screenouts"
                            type="number"
                            name="currency_exchange_rate_screenouts "
                          />
                          <small
                            v-if="validated && errors.length > 0"
                            class="text-danger"
                          >{{ errors[0] }}</small>
                          <small
                            v-if="currency_exchange_rate_screenouts && currency_name"
                            class="text-secondary"
                          >
                            Users will be rewarded {{ currency_exchange_rate_screenouts }} {{ currency_name }} for every
                            1$
                            you earn.
                          </small>
                        </validation-provider>
                      </b-col>
                      <b-col
                        cols="6"
                        style="padding-left: 2.286rem;"
                      >
                        <div class="currency-description">
                          Equivalent of 1 $ in your in-app currency.<br>
                          Only applies to screenout rewards.<br>
                        </div>
                      </b-col>
                    </b-row>

                    <b-row style="margin-top: 2.857rem; padding-bottom: 0.857rem;">
                      <b-col cols="12">
                        <label>Move Decimal Point</label>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        cols="6"
                        style="padding-right: 2.286rem; border-right: 0.143rem solid #ddd;"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Currency exchange rate (Screenout Rewards)"
                          :rules="[
                            (value) => value === null || [0, 1, 2, 3].includes(value) || 'Invalid value. Allowed values: 0, 1, 2, 3'
                          ]"
                        >
                          <b-radio-group
                            v-model="move_decimals"
                            style="margin-top: 0.429rem;"
                          >
                            <b-radio :value="null">
                              No
                            </b-radio>
                            <b-radio :value="1">
                              1 Decimal
                            </b-radio>
                            <b-radio :value="2">
                              2 Decimal
                            </b-radio>
                            <b-radio :value="3">
                              3 Decimal
                            </b-radio>
                          </b-radio-group>
                          <small
                            v-if="validated && errors.length > 0"
                            class="text-danger"
                          >{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                      <b-col
                        cols="6"
                        style="padding-left: 2.286rem;"
                      >
                        <div class="currency-description">
                          Moves the decimal point for your currency.<br>
                          Example: 2 (Shows reward 120 as 1.20)<br>
                          Useful if you reward real currency to your users
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col
                    cols="3"
                  >
                    <div class="w-100 d-block block-attention font-weight-bold mt-4">
                      For each $1 you get, your users get
                    </div>
                    <div class="w-100 h-100 d-flex mt-2">
                      <iframe
                        ref="designMoveDecimalsIframe"
                        style="border: 1px solid #DBE1E6; box-sizing: border-box; border-radius: 4px;
                        overflow:hidden;"
                        frameborder="0"
                        :src="generalIframeUrlPreview"
                        width="190px"
                        height="116px"
                        scrolling="no"
                      />
                    </div>
                  </b-col>
                </b-row>

                <div class="block-attention">
                  <u>Attention:</u>
                  The reward amount is always rounded to a
                  full number. A low <br>exchange rate will result
                  in rounding inaccuracy. Therefore 10 is the
                  minimum)
                </div>

              </div>
            </div>

            <!-- OFFERWALL -->
            <!--            <div class="offer-info-block">-->
            <!--              <div style="padding: 1.143rem 1.714rem">-->
            <!--                <b-media no-body>-->
            <!--                  <b-media-aside class="mr-1">-->
            <!--                    <b-avatar-->
            <!--                      size="40"-->
            <!--                      variant="light-primary"-->
            <!--                    >-->
            <!--                      <feather-icon-->
            <!--                        size="20"-->
            <!--                        icon="PackageIcon"-->
            <!--                      />-->
            <!--                    </b-avatar>-->
            <!--                  </b-media-aside>-->
            <!--                  <b-media-body class="my-auto">-->
            <!--                    <div class="offer-info-block-title">-->
            <!--                      Offerwall-->
            <!--                    </div>-->
            <!--                    <div class="offer-info-block-des">-->
            <!--                      The offerwall shows the user a choice of surveys to complete-->
            <!--                    </div>-->
            <!--                  </b-media-body>-->
            <!--                </b-media>-->
            <!--              </div>-->
            <!--              <hr style="margin: 0;">-->
            <!--              <div style="padding: 1.714rem">-->
            <!--                <b-row style="padding-bottom: 0.857rem;">-->
            <!--                  <b-col cols="12">-->
            <!--                    <label>Survey panel interval</label>-->
            <!--                  </b-col>-->
            <!--                </b-row>-->
            <!--                <b-row>-->
            <!--                  <b-col-->
            <!--                    cols="6"-->
            <!--                    style="padding-right: 2.286rem; border-right: 0.143rem solid #ddd;"-->
            <!--                  >-->
            <!--                    <b-radio-group v-model="survey_panel_interval">-->
            <!--                      <b-radio :value="0">-->
            <!--                        Rarely-->
            <!--                      </b-radio>-->
            <!--                      <b-radio :value="1">-->
            <!--                        Regularly (Recommended)-->
            <!--                      </b-radio>-->
            <!--                      <b-radio :value="2">-->
            <!--                        Always-->
            <!--                      </b-radio>-->
            <!--                    </b-radio-group>-->
            <!--                  </b-col>-->
            <!--                  <b-col-->
            <!--                    cols="6"-->
            <!--                    style="padding-left: 2.286rem;"-->
            <!--                  >-->
            <!--                    <div class="currency-description">-->
            <!--                      Defines how often the survey panel icon will-->
            <!--                      slide in after the user closed it.<br>-->
            <!--                      "Regularly" will usually be the best choice-->
            <!--                      for revenue and user experience-->
            <!--                    </div>-->
            <!--                  </b-col>-->
            <!--                </b-row>-->
            <!--              </div>-->
            <!--            </div>-->
          </b-col>

          <!--          <b-col cols="12">-->
          <!-- SINGLE SURVEY -->
          <!--            <div class="offer-info-block">-->
          <!--              <div style="padding: 1.143rem 1.714rem">-->
          <!--                <b-media no-body>-->
          <!--                  <b-media-aside class="mr-1">-->
          <!--                    <b-avatar-->
          <!--                      size="40"-->
          <!--                      variant="light-primary"-->
          <!--                    >-->
          <!--                      <feather-icon-->
          <!--                        size="20"-->
          <!--                        icon="ActivityIcon"-->
          <!--                      />-->
          <!--                    </b-avatar>-->
          <!--                  </b-media-aside>-->
          <!--                  <b-media-body class="my-auto">-->
          <!--                    <div class="offer-info-block-title">-->
          <!--                      Single Survey-->
          <!--                    </div>-->
          <!--                    <div class="offer-info-block-des">-->
          <!--                      Single Survey links redirect to a single survey. They-->
          <!--                      have a fixed payout <br> for you and reward the user based on-->
          <!--                      the currency exchange rate-->
          <!--                    </div>-->
          <!--                  </b-media-body>-->
          <!--                </b-media>-->
          <!--              </div>-->
          <!--              <hr style="margin: 0;">-->
          <!--              <div style="padding: 1.714rem;">-->
          <!--                <b-row style="padding-bottom: 0.857rem;">-->
          <!--                  <b-col cols="2">-->
          <!--                    <label>Internal Routing</label>-->
          <!--                  </b-col>-->
          <!--                  <b-col-->
          <!--                    v-show="internal_routing !== 0"-->
          <!--                    cols="2"-->
          <!--                  >-->
          <!--                    <label>Additional surveys</label>-->
          <!--                  </b-col>-->
          <!--                </b-row>-->
          <!--                <b-row>-->
          <!--                  <b-col-->
          <!--                    :cols="internal_routing === 0 ? 2 : 4"-->
          <!--                    style="border-right: 0.143rem solid #ddd;"-->
          <!--                  >-->
          <!--                    <validation-provider-->
          <!--                      #default="{ errors }"-->
          <!--                      name="internal routing"-->
          <!--                      :rules="'required|min_value:0'"-->
          <!--                    >-->
          <!--                      <internal-routing-input-->
          <!--                        v-model="internal_routing"-->
          <!--                        name="internal_routing"-->
          <!--                        type="number"-->
          <!--                      />-->
          <!--                      <small-->
          <!--                        v-if="value.isValidated && errors.length > 0"-->
          <!--                        class="text-danger"-->
          <!--                      >Internal routing must be greater than zero</small>-->
          <!--                    </validation-provider>-->
          <!--                  </b-col>-->
          <!--                  <b-col-->
          <!--                    cols="8"-->
          <!--                    style="padding-left: 2.286rem;"-->
          <!--                  >-->
          <!--                    <div-->
          <!--                      class="currency-description"-->
          <!--                      style="line-height: 2.5rem;"-->
          <!--                    >-->
          <!--                      This will redirect the user to another-->
          <!--                      survey in case he gets screened out-->
          <!--                    </div>-->
          <!--                  </b-col>-->
          <!--                </b-row>-->
          <!--              </div>-->
          <!--            </div>-->

          <!-- SMART LINKS -->
          <!--            <div class="offer-info-block">-->
          <!--              <div style="padding: 1.143rem 1.714rem">-->
          <!--                <b-media no-body>-->
          <!--                  <b-media-aside class="mr-1">-->
          <!--                    <b-avatar-->
          <!--                      size="40"-->
          <!--                      variant="light-primary"-->
          <!--                    >-->
          <!--                      <feather-icon-->
          <!--                        size="20"-->
          <!--                        icon="ExternalLinkIcon"-->
          <!--                      />-->
          <!--                    </b-avatar>-->
          <!--                  </b-media-aside>-->
          <!--                  <b-media-body class="my-auto">-->
          <!--                    <h5 class="font-weight-bolder mb-0">-->
          <!--                      Smart Link-->
          <!--                    </h5>-->
          <!--                    <b-card-text class="font-small-3 mb-0 tiny-small">-->
          <!--                      A single survey link selecting the best survey based on-->
          <!--                      the particular user and your settings-->
          <!--                    </b-card-text>-->
          <!--                  </b-media-body>-->
          <!--                </b-media>-->
          <!--              </div>-->
          <!--              <hr style="margin: 0;">-->
          <!--              <div style="padding: 1.714rem;">-->
          <!--                <b-row style="padding-bottom: 0.857rem;">-->
          <!--                  <b-col cols="12">-->
          <!--                    <label>Reward type</label>-->
          <!--                  </b-col>-->
          <!--                </b-row>-->
          <!--                <b-row>-->
          <!--                  <b-col-->
          <!--                    cols="2"-->
          <!--                    style="padding-right: 2.286rem; border-right: 0.143rem solid #ddd;"-->
          <!--                  >-->
          <!--                    <div class="form-inline mb-1">-->
          <!--                      <b-radio-group v-model="reward_type">-->
          <!--                        <b-radio :value="0">-->
          <!--                          Fixed-->
          <!--                        </b-radio>-->
          <!--                        <b-radio :value="1">-->
          <!--                          Variable-->
          <!--                        </b-radio>-->
          <!--                      </b-radio-group>-->
          <!--                    </div>-->
          <!--                  </b-col>-->
          <!--                  <b-col-->
          <!--                    cols="10"-->
          <!--                    style="padding-left: 2.286rem;"-->
          <!--                  >-->
          <!--                    <div class="currency-description">-->
          <!--                      Variable will reward the user based on your-->
          <!--                      payout and the currency exchange rate.<br> Fixed-->
          <!--                      will reward the user always the same amount-->
          <!--                      of in-app currency-->
          <!--                    </div>-->
          <!--                  </b-col>-->
          <!--                </b-row>-->
          <!--                <b-row-->
          <!--                  v-show="parseInt(reward_type) === 0"-->
          <!--                  style="padding-bottom: 0.857rem;"-->
          <!--                >-->
          <!--                  <b-col cols="12">-->
          <!--                    <label>Amount</label>-->
          <!--                  </b-col>-->
          <!--                </b-row>-->
          <!--                <b-row v-show="parseInt(reward_type) === 0">-->
          <!--                  <b-col-->
          <!--                    cols="5"-->
          <!--                    style="padding-right: 2.286rem;"-->
          <!--                  >-->
          <!--                    <validation-provider-->
          <!--                      #default="{ errors }"-->
          <!--                      name="Fixed reward"-->
          <!--                      :rules="(reward_type === 0) ? { required: true, min_value: 10 } : ''"-->
          <!--                    >-->
          <!--                      <b-input-->
          <!--                        v-model="fixed_reward"-->
          <!--                        style="max-width: 28.571rem"-->
          <!--                        type="number"-->
          <!--                      />-->
          <!--                      <div style="clear:both" />-->
          <!--                      <small-->
          <!--                        v-if="value.isValidated && errors.length > 0"-->
          <!--                        class="text-danger"-->
          <!--                      >{{ errors[0] }}</small>-->
          <!--                    </validation-provider>-->
          <!--                  </b-col>-->
          <!--                </b-row>-->
          <!--                <b-row style="padding-bottom: 0.857rem; margin-top: 2.143rem;">-->
          <!--                  <b-col cols="12">-->
          <!--                    <label>Minimum Payout</label>-->
          <!--                  </b-col>-->
          <!--                </b-row>-->
          <!--                <b-row>-->
          <!--                  <b-col-->
          <!--                    cols="5"-->
          <!--                    style="padding-right: 2.286rem; border-right: 0.143rem solid #ddd;"-->
          <!--                  >-->
          <!--                    <b-select v-model="minimum_payout">-->
          <!--                      <b-select-option :value="0">-->
          <!--                        None (Recommended)-->
          <!--                      </b-select-option>-->
          <!--                      <b-select-option :value="0.25">-->
          <!--                        0.25 $-->
          <!--                      </b-select-option>-->
          <!--                      <b-select-option :value="0.5">-->
          <!--                        0.5 $-->
          <!--                      </b-select-option>-->
          <!--                      <b-select-option :value="0.75">-->
          <!--                        0.75 $-->
          <!--                      </b-select-option>-->
          <!--                      <b-select-option :value="1">-->
          <!--                        1.00 $-->
          <!--                      </b-select-option>-->
          <!--                      <b-select-option :value="1.25">-->
          <!--                        1.25 $-->
          <!--                      </b-select-option>-->
          <!--                      <b-select-option :value="1.5">-->
          <!--                        1.5 $-->
          <!--                      </b-select-option>-->
          <!--                      <b-select-option :value="2">-->
          <!--                        2 $-->
          <!--                      </b-select-option>-->
          <!--                    </b-select>-->
          <!--                  </b-col>-->
          <!--                  <b-col-->
          <!--                    cols="7"-->
          <!--                    style="padding-left: 2.286rem;"-->
          <!--                  >-->
          <!--                    <div class="currency-description">-->
          <!--                      The minimum payout you expect for a-->
          <!--                      survey.<br>The higher you go, the smaller-->
          <!--                      the choice of surveys.<br> Low payout surveys-->
          <!--                      may be finished very quickly.-->
          <!--                    </div>-->
          <!--                  </b-col>-->
          <!--                </b-row>-->
          <!--                <b-row style="padding-bottom: 0.857rem; margin-top: 2.143rem;">-->
          <!--                  <b-col cols="12">-->
          <!--                    <label>Maximum Length</label>-->
          <!--                  </b-col>-->
          <!--                </b-row>-->
          <!--                <b-row>-->
          <!--                  <b-col-->
          <!--                    cols="5"-->
          <!--                    style="padding-right: 2.286rem; border-right: 0.143rem solid #ddd;"-->
          <!--                  >-->
          <!--                    <b-select v-model="interview_length">-->
          <!--                      <b-select-option :value="0">-->
          <!--                        None (Recommended)-->
          <!--                      </b-select-option>-->
          <!--                      <b-select-option :value="10">-->
          <!--                        10 Minutes-->
          <!--                      </b-select-option>-->
          <!--                      <b-select-option :value="15">-->
          <!--                        15 Minutes-->
          <!--                      </b-select-option>-->
          <!--                      <b-select-option :value="20">-->
          <!--                        20 Minutes-->
          <!--                      </b-select-option>-->
          <!--                      <b-select-option :value="30">-->
          <!--                        30 Minutes-->
          <!--                      </b-select-option>-->
          <!--                    </b-select>-->
          <!--                  </b-col>-->
          <!--                  <b-col-->
          <!--                    cols="7"-->
          <!--                    style="padding-left: 2.286rem;"-->
          <!--                  >-->
          <!--                    <div class="currency-description">-->
          <!--                      The maximum interview length of a survey.<br>The-->
          <!--                      lower you go, the smaller the choice of-->
          <!--                      surveys.<br> Long surveys may be rewarded-->
          <!--                      extraordinary well.-->
          <!--                    </div>-->
          <!--                  </b-col>-->
          <!--                </b-row>-->
          <!--              </div>-->
          <!--            </div>-->

          <!-- Bonus -->
          <b-col>
            <div class="offer-info-block">
              <div style="padding: 1.143rem 1.714rem">
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar
                      size="40"
                      variant="light-primary"
                    >
                      <feather-icon
                        size="20"
                        icon="ActivityIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <div class="offer-info-block-title">
                      Bonus
                    </div>
                    <div class="offer-info-block-des">
                      Activate a temporary bonus for all your users. The bonus applies to your exchange rate.
                      If you normally pay 100 Points for every $1 revenue, you will pay users 110 Points when a 10%
                      bonus is active.
                    </div>
                  </b-media-body>
                </b-media>
              </div>
              <hr style="margin: 0;">
              <div
                style="padding: 1.714rem;"
              >
                <div class="row">
                  <div class="col-8 row d-flex justify-content-between align-items-start">
                    <div class="col-2">
                      <label>Bonus active</label>
                      <b-form-checkbox
                        v-model="bonus_active"
                        switch
                        class="mb-2"
                        :unchecked-value="0"
                        :value="1"
                      />
                    </div>
                    <div
                      v-if="bonus_active"
                      class="col-3"
                    >
                      <label>Bonus Percentage</label>
                      <validation-provider
                        v-slot="{ errors }"
                        name="bonus percentage"
                        :rules="{ required: true, max_value: 100 }"
                      >
                        <b-input-group append="%">
                          <b-input
                            v-model="user_bonus"
                            type="number"
                            name="user_bonus"
                          />
                        </b-input-group>

                        <small
                          v-if="validated && errors.length > 0"
                          class="text-danger"
                        >{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <div
                      v-if="bonus_active"
                      class="col-5"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="user_bonus_expires_at"
                        :rules="{ required: true }"
                      >
                        <label>Bonus Expiry</label>
                        <b-form-datepicker
                          v-model="user_bonus_expires_at"
                          placeholder="Select date"
                        />
                        <small
                          v-if="validated && errors.length > 0"
                          class="text-danger"
                        >{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                    <div class="col-1" />
                  </div>
                  <div
                    class="col-4"
                  >
                    <!--                    <img-->
                    <!--                      src="@/assets/images/pages/bonus.webp"-->
                    <!--                      alt="Bonus Info"-->
                    <!--                      class="img-fluid"-->
                    <!--                    >-->
                    <iframe
                      ref="designBonusIframe"
                      style="border: 1px solid #DBE1E6; box-sizing: border-box; border-radius: 4px;
                        overflow:hidden;"
                      frameborder="0"
                      :src="computedBonusIframeUrlPreview"
                      scrolling="no"
                      :height="user_bonus !== null ? 250 : 155"
                      width="360"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'
// import InternalRoutingInput from './elements/InternalRoutingInput.vue'

export default {
  components: {
    // InternalRoutingInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: ['value', 'iFrameUrlQuery'],
  data() {
    return {
      currency_name: 'usd',
      currency_exchange_rate: null,
      currency_exchange_rate_screenouts: null,
      // survey_panel_interval: null,
      // internal_routing: null,
      // minimum_payout: null,
      // interview_length: null,
      // fixed_reward: null,
      // reward_type: 1,
      bonus_active: false,
      user_bonus: null,
      user_bonus_expires_at: null,
      mounted: false,
      move_decimals: null,
      generalIframeUrlPreview: '',
      bonusIframeUrlPreview: '',
    }
  },
  computed: {
    watchParentValue() {
      return [
        this.value.currency_name,
        this.value.currency_exchange_rate,
        this.value.currency_exchange_rate_screenouts,
        // this.value.survey_panel_interval,
        // this.value.internal_routing,
        // this.value.minimum_payout,
        // this.value.interview_length,
        // this.value.fixed_reward,
        // this.value.reward_type,
        this.value.user_bonus,
        this.value.user_bonus_expires_at,
        this.value.move_decimals,
      ]
    },
    iframeBaseUrl() {
      const SURVEY_BASE_URL = process.env.VUE_APP_SURVEY_BASE_URL

      return `${SURVEY_BASE_URL}/design-preview`
    },
    timeLeftInSeconds() {
      if (!this.user_bonus_expires_at) return null

      const currentTimestamp = Math.floor(Date.now() / 1000) // Current timestamp in seconds
      const expirationTimestamp = Math.floor(new Date(this.user_bonus_expires_at).getTime() / 1000) // Convert selected date to timestamp in seconds

      // Calculate the difference (time left) in seconds
      const timeLeft = expirationTimestamp - currentTimestamp

      // If the time left is negative, it means the date is in the past
      return timeLeft > 0 ? timeLeft : 0
    },
    computedBonusIframeUrlPreview() {
      let src = `${this.bonusIframeUrlPreview}`

      // Conditionally add bonus_per if it's not null
      if (this.user_bonus !== null) {
        src += `&bonus_per=${this.user_bonus}&bonus_exp=${this.timeLeftInSeconds}`
      }

      return src
    },
  },
  watch: {
    $data: {
      handler() {
        if (this.mounted) {
          const appData = this.value
          appData.currency_name = this.currency_name
          appData.currency_exchange_rate = this.currency_exchange_rate
          appData.currency_exchange_rate_screenouts = this.currency_exchange_rate_screenouts
          // appData.survey_panel_interval = this.survey_panel_interval
          // appData.internal_routing = this.internal_routing
          // appData.minimum_payout = this.minimum_payout
          // appData.interview_length = this.interview_length
          // appData.fixed_reward = this.fixed_reward
          // appData.reward_type = this.reward_type
          appData.user_bonus = this.user_bonus
          appData.user_bonus_expires_at = this.user_bonus_expires_at
          appData.move_decimals = this.move_decimals
          this.$emit('input', appData)
        }
      },
      deep: true,
    },
    watchParentValue() {
      this.init_data()
    },
    bonus_active(value) {
      if (value === 0) {
        this.user_bonus = null
        this.user_bonus_expires_at = null
      }
    },
    iFrameUrlQuery: {
      immediate: true,
      deep: true,
      handler(newVal) {
        // Create a URLSearchParams object from the new query parameters
        const params = new URLSearchParams(newVal)

        // Define the params you want to filter out
        const paramsToRemove = ['app', 'cr_col', 'header', 'img', 'pol_url', 'show_filters', 'stats']

        // Iterate over params and remove those specified in paramsToRemove
        paramsToRemove.forEach(param => params.delete(param))

        // Construct the filtered URL
        this.generalIframeUrlPreview = `${this.iframeBaseUrl}/survey?${params.toString()}`
        this.bonusIframeUrlPreview = `${this.iframeBaseUrl}/bonus?${params.toString()}`
      },
    },
  },
  mounted() {
    this.init_data()
    this.mounted = true
    window.AppEditRewards = this
    this.bonus_active = this.value.user_bonus && this.value.user_bonus_expires_at ? 1 : 0
  },
  methods: {
    reset_data() {
      this.$emit('reset', [
        'currency_name',
        'currency_exchange_rate',
        // 'survey_panel_interval',
        // 'internal_routing',
        // 'minimum_payout',
        // 'interview_length',
        // 'fixed_reward',
        // 'reward_type',
        'bonus_active',
        'user_bonus',
        'user_bonus_expires_at',
        'move_decimals',
      ])
    },
    init_data() {
      this.$data.currency_name = this.value.currency_name
      this.$data.currency_exchange_rate = this.value.currency_exchange_rate
      this.$data.currency_exchange_rate_screenouts = this.value.currency_exchange_rate_screenouts
      // this.$data.survey_panel_interval = this.value.survey_panel_interval
      // this.$data.internal_routing = this.value.internal_routing
      // this.$data.minimum_payout = this.value.minimum_payout
      // this.$data.interview_length = this.value.interview_length
      // this.$data.fixed_reward = this.value.fixed_reward
      // this.$data.reward_type = this.value.reward_type
      this.$data.user_bonus = this.value.user_bonus
      this.$data.user_bonus_expires_at = this.value.user_bonus_expires_at
      this.$data.move_decimals = this.value.move_decimals
    },
  },
}
</script>
<style scoped lang="scss">
.offer-info-block {
  background: #F8F8F8;
  border-radius: 0.429rem;
  margin-bottom: 0.857rem;

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 100%;
    color: #5E5873;
  }
}

.offer-info-block-title {
  font-weight: 600;
  font-size: 1.071rem;
  line-height: 1.286rem;
  color: #5E5873;
}

.currency-description {
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 140%;
  color: #6E6B7B;
}

.block-attention {
  margin-top: 1.143rem;
  padding: 1.143rem;
  background: rgba(255, 159, 67, 0.12);
  border-radius: 0.429rem;
  font-size: 1rem;
  line-height: 140%;

  u {
    font-weight: 600;
    color: #FF9F43;
  }
}
</style>
